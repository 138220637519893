import { Entity } from "../models";
import { SizeTableSchema } from "../schemas";
import EntityModel from "./EntityModel";

class SizeTableEntity extends EntityModel<SizeTableSchema> {
    readonly title: string;
    readonly sizeChart: string;

    constructor(entity: Entity<SizeTableSchema>) {
        super(entity);
        this.title = entity.getFirstAttribute("title")?.getFirstValue()?.value || "";
        this.sizeChart = entity.getFirstAttribute("size_chart")?.getFirstValue()?.value || "";
    }
}

export default SizeTableEntity;
